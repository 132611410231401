export default () => {
  // MODAL WINDOWS
  if (document.getElementById('modal') !== null) {
    $('#modal, #modal-add-vehicle').iziModal({
      width: 800,
      padding: 40,
      closeButton: true,
      headerColor: '#4287c1',
      focusInput: false,
      top: 20,
      appendTo: 'body',
      appendToOverlay: 'body',
      onClosed: function () {
        //reset modal content to show first tab
        $('#modal-header a:eq(0)').addClass('active').siblings('a').removeClass('active')
        $('#modal .modal-tab:eq(0)').removeClass('hide').siblings('.modal-tab').addClass('hide')
      },
    })

    $('.modal-confirm').click(function (e) {
      e.preventDefault()
      $('#modal-confirm').iziModal('open')
    })

    $('.modal-register').click(function (e) {
      e.preventDefault()
      $('#modal').iziModal('open')
    })

    $('#modal').on('click', '#modal-header a', function (event) {
      event.preventDefault()
      var index = $(this).index()
      $(this).addClass('active').siblings('a').removeClass('active')
      $(this).parents('div').find('.modal-tab').eq(index).removeClass('hide').siblings('.modal-tab').addClass('hide')
    })

    $('#modal').on('click', '.register', function (event) {
      $('#modal').iziModal('close')
      setTimeout(function () {
        $('#modal-success').iziModal('open')
      }, 500)
    })
  }

  if (document.getElementById('modal-success') !== null) {
    $('#modal-success').iziModal({
      headerColor: '#4287c1',
      width: 800,
      padding: 40,
      timeout: 5000,
      pauseOnHover: false,
      timeoutProgressbar: true,
    })
  }

  if (document.getElementById('modal-confirm') !== null) {
    $('#modal-confirm').iziModal({
      width: 500,
      padding: 40,
      closeButton: true,
      headerColor: '#4287c1',
      focusInput: false,
      appendTo: 'body',
      appendToOverlay: 'body',
    })
  }

  $('#modal-empty-bg').click(function () {
    closeAlertModal()
  })
}
