const sharedModal = () => {
  // Shared modal rendered on the application layout, usable everywhere
  // Handles remote forms (local: false or remote: true) inside the modal by default
  // Additional logic (updates to the DOM etc) can be added to a separate ajax:success event listener on a case-by-case basis
  // Listen to the ajax:success event after receiving the custom event 'modalLoaded', which includes the modal element in event.detail
  const $sharedModal = $('#shared-modal')
  $sharedModal.iziModal({ zindex: 20000 })
  $sharedModal.iziModal({ zindex: 20000 }) // This won't work in some places without a duplicate call (example: teacher's calendar events -> show modal for driving event -> edit participation (pencil icon))

  $('body').on('click', (e) => {
    const element = e.target.closest('[data-shared-modal-url]')
    if (!element) return
    e.preventDefault()

    $.get(element.dataset.sharedModalUrl, (html) => {
      $sharedModal.iziModal('setContent', html)
      $sharedModal.iziModal('open')
      element.dispatchEvent(new CustomEvent('modalLoaded', { detail: $sharedModal }))
      handleModalAjaxForm($sharedModal.find('form[data-remote="true"]:not([x-data="modalForm"])'))
    }).fail(({ status, statusText }) => alert(`${status} ${statusText}`))
  })
}

export { sharedModal }
