const openAlertModal = (mode, topic, text, callback) => {
  if (!document.querySelector('.modal-bg')) return

  var twoButton = mode === 2
  $('#no-button').toggle(twoButton)
  $('#yes-button').toggle(twoButton)
  $('#ok-button').toggle(!twoButton)

  if (topic) {
    $('#modal-header-text').html(topic)
  }
  if (text) {
    $('#modal-content-text').html(text)
  }

  if (twoButton) {
    $('.yes-button')
      .off('click')
      .click(function () {
        closeAlertModal()
        if (callback instanceof Function) {
          callback()
        }
      })
    $('#modal-empty-bg')
      .off('click')
      .click(function () {
        closeAlertModal()
      })
  } else {
    $('.ok-button, #modal-empty-bg')
      .off('click')
      .click(function () {
        closeAlertModal()
        if (callback instanceof Function) {
          callback()
        }
      })
  }

  $('.modal-bg').css({ display: 'inline-block' })

  $(window).resize(function () {
    fix_size()
    fix_position()
  })

  fix_size()
  function fix_size() {
    if ($('.modal-bg').css('display') === 'none') {
      $('.modal-bg').css({ display: 'inline-block', visibility: 'hidden' })
    }

    var header_text_height = parseInt($('.modal-header-text').css('height'))
    header_text_height = header_text_height + 20
    $('.modal-header').css({ height: header_text_height })

    var content_text_height = parseInt($('#modal-content-text').css('height'))
    var new_content_height = content_text_height + 40
    $('.modal-content').css({ height: new_content_height })

    var text_offset =
      (parseInt($('.modal-content').css('height')) - parseInt($('#modal-content-text').css('height'))) / 2
    $('#modal-content-text').css({ 'margin-top': text_offset })

    if (parseInt($('.modal-content').css('width')) - parseInt($('#modal-content-text').css('width')) > 100) {
      var new_width = 1.5 * parseInt($('#modal-content-text').css('width'))
      $('#modal-graphic').css({ 'max-width': new_width })
    }

    if ($('.modal-bg').css('visibility') === 'hidden') {
      $('.modal-bg').css({ display: 'none', visibility: 'visible' })
    }
  }

  fix_position()
  function fix_position() {
    if ($('.modal-bg').css('display') === 'none') {
      $('.modal-bg').css({ display: 'inline-block', visibility: 'hidden' })
    }

    var text_area_margin = parseInt($('#modal-content-text').css('margin-top'))
    var text_area_height = parseInt($('#modal-content-text').css('height')) + text_area_margin + 20

    var header_height = parseInt($('.modal-header').css('height'))
    var footer_height = parseInt($('.modal-footer').css('height'))

    var new_bg_height = text_area_height + header_height + footer_height

    var full_window_height = window.innerHeight
    var full_window_width = window.innerWidth

    var vertical_offset = (full_window_height - new_bg_height) / 2
    var horizontal_offset = (full_window_width - parseInt($('#modal-graphic').css('width'))) / 2

    $('#modal-graphic').css({ top: vertical_offset })
    $('#modal-graphic').css({ left: horizontal_offset })

    if ($('.modal-bg').css('visibility') === 'hidden') {
      $('.modal-bg').css({ display: 'none', visibility: 'visible' })
    }
  }

  $('.no-button, .yes-button, .ok-button, #modal-empty-bg').click(function () {
    closeAlertModal()
  })
}

const closeAlertModal = () => {
  $('.modal-bg').css({ display: 'none' })
}

export { openAlertModal, closeAlertModal }
