export const warningModal = (topic, main_text, callback, callback_cancel, safe_warning_text) => {
  var $modal = $('#warning_modal')
  var can_cancel = true
  var scroll = $('body').scrollTop()
  $modal.iziModal({ transitionIn: 'fadeInUp', zindex: 2000 })

  if (topic) $modal.find('.iziModal-header-title').html(topic)
  if (main_text) $('#warning_text').html(main_text)
  document.getElementById("warning_safe_box").checked = false

  if (!!safe_warning_text) {
    $('#warning_safe_text').html(safe_warning_text)
    $('#warning_safe').toggle(true)

  } else {
    $('#warning_safe').toggle(false)
  }

  if (callback) {
    $('#warning_continue_btn')
      .off('click')
      .on('click', function () {
        if (!!safe_warning_text && !document.getElementById("warning_safe_box").checked) {
          return;
        }

        can_cancel = false
        callback()
        $modal.iziModal('close')
      })
  } else {
    $('#warning_continue_btn').off('click')
  }

  $modal.off('dialogclose').on('dialogclose', function (event) {
    if (callback_cancel && can_cancel) callback_cancel()
    event.preventDefault()
    $('body').scrollTop(scroll)
  })

  $modal.iziModal('open')
}
