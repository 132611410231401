type Century = 1700 | 1800 | 1900 | 2000

export class Ssn {
  value: string
  birthCentury: Century | null
  dateOfBirth: Date | null
  separator: string | undefined
  dobString: string | undefined
  identifier: string | undefined
  validationCharacter: string | undefined

  static validate(input: HTMLInputElement) {
    new Ssn(input.value).validateInput(input)
  }

  constructor(value: string) {
    this.value = value?.toUpperCase()?.replaceAll(/\s/g, '')
    const regex = /^(?<dobString>\d{6})(?<separator>.)(?<identifier>\d{3})(?<validationCharacter>.)$/
    Object.assign(this, this.value.match(regex)?.groups)
    this.birthCentury = this.#getBirthCentury()
    this.dateOfBirth = this.#calculateDateOfBirth()
  }

  validateInput(input: HTMLInputElement) {
    const errorMsg = window.I18n.invalid_ssn || 'Invalid SSN'
    input.setCustomValidity(this.value && !this.valid ? errorMsg : '')
  }

  get age() {
    return this.dateOfBirth ? this.#calculateAge() : -1
  }

  get valid() {
    return this.validLength && this.validChecksum && this.validDateOfBirth
  }

  get validLength() {
    return this.value.length === 11
  }

  get validChecksum() {
    if (!this.validationCharacter || !this.dobString || !this.identifier) return false

    const checksum = parseInt(this.dobString + this.identifier) % 31
    return this.validationCharacter === '0123456789ABCDEFHJKLMNPRSTUVWXY'[checksum]
  }

  get validDateOfBirth() {
    return !!this.dateOfBirth && !!this.birthCentury
  }

  #getBirthCentury(): Century | null {
    switch (this.separator) {
      case '+':
        return 1800
      case '-':
      case 'Y':
      case 'X':
      case 'W':
      case 'V':
      case 'U':
        return 1900
      case 'A':
      case 'B':
      case 'C':
      case 'D':
      case 'E':
      case 'F':
        return 2000
      default:
        return null
    }
  }

  #calculateDateOfBirth() {
    if (!this.birthCentury || !this.dobString) return null

    const [day, month, yearShort] = (this.dobString.match(/\d{2}/g) || []).map((s) => +s)
    if (day === undefined || month === undefined || yearShort === undefined) {
      return null
    }

    const year = this.birthCentury + yearShort
    const dob = new Date(year, month - 1, day)

    return day !== dob.getDate() || month - 1 !== dob.getMonth() ? null : dob
  }

  #calculateAge() {
    if (!this.dateOfBirth) return -1

    const today = new Date()
    const years = today.getFullYear() - this.dateOfBirth.getFullYear()
    const monthOffset = today.getMonth() - this.dateOfBirth.getMonth()
    const dayOffset = today.getDate() - this.dateOfBirth.getDate()
    const beforeBirthday = monthOffset < 0 || (monthOffset === 0 && dayOffset < 0)

    return beforeBirthday ? years - 1 : years
  }
}
