// Show a loading spinner while asynchronous requests are executing
export const loadingSpinner = (show: boolean) => {
  const spinner: HTMLElement | null = document.querySelector('.loading')
  if (!spinner) return

  try {
    // The spinner uses the Popover API in order to be placed on top of <dialog> elements
    show ? spinner.showPopover() : spinner.hidePopover()
  } catch {
    // Unsupported browsers will throw a TypeError which can be ignored
  } finally {
    // The [hidden] attribute is a fallback for unsupported browsers
    spinner.hidden = !show
  }
}
