customElements.define(
  'flash-message',
  class extends HTMLElement {
    constructor() {
      super()
      const template = document.getElementById('flash-message-template').content
      const type = this.getAttribute('type')
      const node = template.cloneNode(true)
      node.querySelector('.flash-message').classList.add(type)

      const shadowRoot = this.attachShadow({ mode: 'open' })
      shadowRoot.appendChild(node)

      this.container = this.closest('#flash-container')
      this.closeBtn = shadowRoot.querySelector('.close-btn')
    }

    connectedCallback() {
      try {
        this.container.showPopover()
      } catch {
        // Browsers that don't support popovers will still see the message, it just won't be in the top-layer
      }

      this.closeBtn.addEventListener('click', () => this.remove())
      setTimeout(() => this.remove(), 5000)
    }
  }
)

export const flash = (type, message) => {
  const flashContainer = document.querySelector('#flash-container')
  const html = `<flash-message type="${type}">${message}</flash-message>`
  flashContainer.insertAdjacentHTML('beforeend', html)
}
