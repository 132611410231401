import Rails from '@rails/ujs'

// In production builds, this complains about already being loaded.
// Not an issue in development, so there's something weird going on here...
// UJS is being deprecated in future versions of Rails anyway (https://github.com/rails/rails/pull/43418),
//   so instead of trying to fix this, it's better to start migrating things to Turbo
if (!window._rails_loaded) {
  Rails.start()
}

// 3rd party libraries
import moment from 'moment'
window.moment = moment

import flatpickr from 'flatpickr'
flatpickr.l10ns.default.firstDayOfWeek = 1
flatpickr.defaultConfig.time_24hr = true
flatpickr.defaultConfig.altInputClass = ''
window.flatpickr = flatpickr

// NOTE: The Datatables imports need to be very specific and in a specific order to work!
// The default import statements provided by the download manager on their website didn't work for some reason
// Changing the main libraries to versions without the suggested "-dt" suffix seems to have fixed most of the issues
import JSZip from 'jszip'
window.JSZip = JSZip
import 'datatables.net'
import 'datatables.net-responsive'
import 'datatables.net-buttons'
import 'datatables.net-buttons/js/buttons.html5.mjs'
import 'datatables.net-buttons/js/buttons.print.mjs'

// Components
import { flash } from '~/web_components/flash_message'
import { warningModal } from '~/utils/warning_modal'
import { openAlertModal, closeAlertModal } from '~/utils/alert_modal'
import { loadingSpinner } from '~/utils/loading_spinner'
window.flash = flash
window.loadingSpinner = loadingSpinner

// Utilities
import { Ssn } from '~/utils/ssn'
import { handleModalAjaxForm, ajaxErrorMessage, renderFormErrors } from '~/utils/ajax_forms'
window.warningModal = warningModal
window.openAlertModal = openAlertModal
window.closeAlertModal = closeAlertModal
window.Ssn = Ssn
window.handleModalAjaxForm = handleModalAjaxForm
window.ajaxErrorMessage = ajaxErrorMessage
window.renderFormErrors = renderFormErrors

// Initialize global functions
import { sharedModal } from '~/utils/modal'
import modals from '~/utils/modals'
document.addEventListener('DOMContentLoaded', () => {
  sharedModal()
  modals()
})

// Define types for extensions on Window
declare global {
  interface Window {
    flash: typeof flash
    loadingSpinner: typeof loadingSpinner
    warningModal: typeof warningModal
    openAlertModal: typeof openAlertModal
    closeAlertModal: typeof closeAlertModal
    Ssn: typeof Ssn
    handleModalAjaxForm: typeof handleModalAjaxForm
    ajaxErrorMessage: typeof ajaxErrorMessage
    renderFormErrors: typeof renderFormErrors
    JSZip: typeof JSZip
  }
}
